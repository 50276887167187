var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"gl-flex gl-items-center gl-justify-center gl-text-sm",class:{
    'comment-indicator gl-border-0 gl-bg-transparent': _vm.isNewNote,
    'js-image-badge design-note-pin': !_vm.isNewNote,
    resolved: _vm.isResolved,
    inactive: _vm.isInactive,
    draft: _vm.isDraft,
    'on-image': _vm.isOnImage,
    'gl-absolute': _vm.position,
    small: _vm.size === 'sm',
  },style:(_vm.position),attrs:{"aria-label":_vm.pinLabel,"type":"button"},on:{"mousedown":function($event){return _vm.$emit('mousedown', $event)},"mouseup":function($event){return _vm.$emit('mouseup', $event)},"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.isNewNote)?_c('gl-icon',{staticClass:"gl-rounded-full gl-border-2 gl-border-solid gl-border-neutral-0 gl-bg-neutral-0 gl-text-neutral-950",attrs:{"name":"image-comment-dark","size":24}}):[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }